/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";

export default function useprojectsList() {
  const refprojectListTable = ref(null);

  const perPage = ref(10);
  const totalprojects = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refprojectListTable.value
      ? refprojectListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalprojects.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalprojects,
    dataMeta,
    refprojectListTable,
  };
}
